.arrow {
  width: 1rem;
}
.narrow {
  width: 8rem;
  padding-left: 0 !important;
  padding-right: 0 !important;
  font-size: 0.85em;
}
.medium {
  width: 12rem;
  font-size: 0.85em;
}
.wide {
  width: 30rem;
  font-size: 0.85em;
}
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.form-group {
  height: auto !important;
}
.flex {
  display: flex;
}
.flexLeft {
  margin: auto;
  margin-left: 0;
}
.form-label {
  border-right: 0 !important;
}

.find-users {
  padding-right: 50px;
}
.dedicatednumbers-table {
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 0%;
  text-align: left;
}
.checkbox-width {
  text-align: right;
  width: 0% !important;
  height: 0% !important;
}
.campaigns-dropdown-menu {
  padding-top: 0%;
  width: 75%;
  padding-bottom: 0%;
  padding-right: 0%;
  padding-left: 0%;
  height: 25%
}
