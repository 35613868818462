@media all and (min-width: 480px) {
  .customerVerify {
    padding: 4rem 0;
  }

  .customerVerify-no-padding {
    padding: 0 0;
  }

  .customerVerify-padding {
    padding: 3rem 0;
  }

  .customerVerify {
    margin: 0 auto;
    width: 30rem;
    max-width: 32rem;
    text-align: center;
    border: 1px solid lightgrey;
    border-radius: 2rem;
    margin-top: 2rem;
    margin-bottom: 3rem;
  }

  .customerVerify .form-group .form-control {
    width: 100%;
  }

  .roxImgHolder {
    width: 100%;
    text-align: center;
  }

  .customerVerify form,
  .verify {
    /*padding-left: 3rem;*/
    /*padding-right: 3rem;*/
    text-align: left;
  }

  .verify {
    display: flex;
  }

  .verify > span {
    margin: 0 auto;
  }

  button.LoaderButton.btn.btn-primary.btn-block {
    /*width: 75%;*/
    margin: 0 auto;
    margin-bottom: 0.5rem;
    border-radius: 5px;
  }
}
