.marginRight {
	margin-right: 0.4rem;
}

.marginTop {
	margin-top: 0.4rem;
}

.marginTopMedium {
	margin-top: 0.8rem;
}

.marginBottomSmall {
	margin-bottom: 0.4rem;
}

.marginBottomMedium {
	margin-top: 0.8rem;
}

.halfWidth {
	width: 50%;
}

select.halfWidth {
	width: 35%;
}

.halfWidth > input {
	width: 100%;
}

.bigFlex {
	display: flex;
}

.errText {
	color: red;
}

.tabs-container{
	position:inherit;
	display:table;
	z-index: 10;
}

.tab{
	display:table-cell
}

.nav-link {
	display: table-cell;
	width:auto;
	/* background-color: #DAE0E5; */
}

.tab-content{
	position:relative;
	top:auto;
	width: 100%;
}

.tab-content>.active {
	position: relative;
	width: calc(100% - 30px);
}

.navbar-expand .navbar-nav .nav-link {
	/* background-image: linear-gradient(#2e2e2e,#1b1919); */
}

.navbar-dark .navbar-nav .nav-link.active {
	background: #11115522;
}

.table {
	z-index: 100;
}

.global-list{

}

.maxWidth{
	max-width: 180px;
}

.add-button{

}

tbody tr:nth-child(even) {background: whitesmoke}
table.no-table-row-background tbody tr:nth-child(even) {background: #FFF}
tbody tr:nth-child(odd) {background: #FFF}