.page-item {
    min-width: 2.6rem;
}

.page-link {
  border: 0px;
}

.flex-modal {
  display:flex;
}

.auto {
  margin:auto;
  display:flex;
  border: 1px solid #dae0e5 !important;
}

.pagination-item .page-link, .pagination-ellipsis .page-link, .pagination-steppers .page-link {
  color: #1F4984;
  text-align: center;
}

.page-item.active .page-link {
  background-color: #1F4984;
  border-color: #1F4984;
}

.pagination-dropdown {
  width: 5rem;
  height: auto;
}