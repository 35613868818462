.trial-bg {
  background-color: yellow;
}

.suspended-bg {
  background-color: green;
}

.dedicated-bg {
  background-color: gray;
}