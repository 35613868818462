@media all and (min-width: 480px) {
  .Signup {
    padding: 60px 0;
  }

  .Signup form {
    margin: 0 auto;
    max-width: 320px;
  }
}

.backLinkForgotPassword {
  float: left;
  margin-top: 2rem;
  text-align: left;
  margin-left: 2rem;
}

.linkContactSupport {
  float: right;
  margin-top: 2rem;
  text-align: right;
  margin-right: 2rem;
}
.linkContactSupport > span {
  line-height: 1;
  position: relative;
  top: -0.4em;
}

.registerForm {
  min-width: 22rem;
  padding-top: 5%;
  margin: 0 auto;
  max-width: 620px;
}

.registerSuccess {
  min-width: 22rem;
  width: 22rem;
  margin: 0 auto;
  padding-top: 5%;
  font-size: 0.8rem;
}

.registerSuccess h5 {
  text-align: center;
}

.Signup form span.help-block {
  font-size: 14px;
  padding-bottom: 10px;
  color: #999;
}
.errorText {
  color: red;
  text-align: center;
  padding-bottom: 0.8rem;
  font-size: 0.8rem;
}
.textLeft {
  text-align: left;
}
.textRight {
  text-align: right;
}
.text-center {
  text-align: center;
}
.register-header {
  padding-bottom: 1rem;
}
