/*.Home .lander {*/
/*padding: 80px 0;*/
/*text-align: center;*/
/*}*/
/**/
/*.Home .lander h1 {*/
/*font-family: "Open Sans", sans-serif;*/
/*font-weight: 600;*/
/*}*/
/**/
/*.Home .lander p {*/
/*color: #999;*/
/*}*/
.filter-container {
  padding: 0;
}
.filter-container > :nth-child(n) {
  margin: 0;
  left: 0;
  /* padding-left:0; */
}
.maxHeight.form-control {
  min-width: 15rem;
  width: 15.6rem;
}
.tableSearch.form-control {
  min-width: 15rem;
  width: 15.6rem;
}

@media (max-width: 600px) {
  .maxHeight.form-control {
    width: 100%;
  }
  .tableSearch.form-control {
    width: 100%;
  }
}
.flex-account {
  display: flex;
  /*width:22rem;*/
  width: 15.7rem;
  border: 1px solid #dbdbdb;
  border-radius: 5px;
  padding: 6px;
  height: 2.5rem;
}
.red {
  color: red;
  /*text-decoration: underline;*/
  cursor: pointer;
}
.blue {
  color: blue;
  /*text-decoration: underline;*/
  cursor: pointer;
  vertical-align: middle;
}
.buttonRight {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.auto {
  margin: auto;
  display: flex;
}
.borderless-auto {
  /* margin:auto; */
  display: flex;
}
.auto :nth-child(n) {
  margin: auto 0;
  margin-right: 0;
}
.auto input:nth-child(n) {
  /*border:none;*/
  min-width: 0;
  width: 100%;
  margin: auto 0;
  /*height: inherit;*/
}
.auto input {
  width: 49%;
}
.auto input:focus {
  outline: none;
  border-bottom: 2px solid #008489;
  box-shadow: none;
  border-radius: 0;
}
.date-selector {
  display: flex;
}
.date-selector > :nth-child(2),
.price-range > :nth-child(2) {
  margin-left: 1rem;
}
.price-range {
  display: flex;
}
.small-title {
  font-size: 0.8rem;
  margin-left: 5px;
}
.small-icon {
  font-size: 1.2rem;
}
.invoice-table {
  text-align: left;
  border-bottom: 1px #dee2e6 solid;
  border-right: 1px #dee2e6 solid;
  border-left: 1px #dee2e6 solid;
}
.invoice-table td {
  vertical-align: middle;
}
.form-row .col {
  flex-grow: 0.2;
}
.page-item {
  min-width: 2.6rem;
}
.flex-modal {
  display: flex;
}
.wide-table {
  width: 100%;
}
/* 
  START PADDING PRESETS 
*/
.pad-small {
  padding: 0.3rem;
}
.pad-medium {
  padding: 0.6rem;
}
.pad-large {
  padding: 1rem;
}
/*
  END PADDING PRESETS 
*/

/*
  START SINGLE INVOICE STYLING
*/
.invoice-header-box {
  display: inline-block;
  width: 100%;
}
.float-left {
  float: left;
}
.float-right {
  float: right;
}
.pad-table-left {
  padding-left: 0.6rem;
}
.pad-table-left-big {
  padding-left: 5rem;
}
.pad-table-right {
  padding-right: 0.6rem;
}
.payment-cell-thin {
  border-bottom: 1px solid black;
  width: 150px;
}
.payment-cell-thick {
  border-bottom: 2px solid black;
  width: 150px;
}
.surcharge-warning {
  margin: 5px 0px 5px 0px;
}
.invoices-dropdown-icon {
  padding: 0.1rem;
  line-height: 0;
  color: black;
  background-color: transparent;
  border: none !important;
}
.truncated-text {
  max-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 0.85em;
  text-align: left;
  min-width: 280px;
}
.currency-padding {
  padding-left: 0% !important;
  font-size: 0.85em;
  text-align: right;
  max-width: 0;
}
.invoice-table-cells {
  padding-right: 0% !important;
  font-size: 0.85em;
  text-align: left;
  max-width: 0;
}
.currency-label-text-right {
  background-color: aliceblue;
  font-size: 0.85em;
  text-align: right;
}
.status-transition {
  padding: 0.1rem 0.3rem;
  font-size: 0.9rem;
}
.invoices-dropdown-menu {
  padding-top: 0%;
  min-width: 0.3rem;
  padding-bottom: 0%;
}
.hide-dropdown-arrow.dropdown-toggle::after {
  display: none !important;
}

.td.pad-right,
.th.pad-right {
  padding-left: 0rem;
  padding-right: 0.5rem;
}
.td.pad-left,
.th.pad-left {
  padding-right: 0rem;
  padding-left: 0.5rem;
}

.biller-address {
  width: 25.625rem;
}

.invoice-header-flex {
  display: flex;
  justify-content: space-between;
}

/*
  END SINGLE INVOICE STYLING
*/
