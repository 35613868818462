.arrow {
  width: 1rem;
}
.narrow {
  width: 4rem;
  max-width: 60px;
  padding-left: 0 !important;
  padding-right: 0 !important;
  font-size: 85%;
  overflow: unset;
}

.marginTop {
  margin-top: 1rem;
}

.medium {
  width: 12rem;
  max-width: 180px;
  font-size: 85%;
  text-align: right;
}
.commission-report .medium {
  width: 12rem;
  max-width: 180cm;
  font-size: 1rem;
  text-align: left;
}
.wide {
  width: 30rem;
  font-size: 85%;
}

.pdfPassword {
  float: right;
}

.table-click {
  cursor: pointer;
}

@media (min-width: 800px) {
  .half-width {
    width: 50%;
  }

  input[type='text'].form-control:not(.is-invalid) {
    margin-bottom: 0.8rem;
  }
}

.table td {
  /*padding:0;*/
}

.tableBottom {
  /*padding:0 !important;*/
  background-color: aliceblue;
  border-top: none !important;
  font-weight: bold;
  font-size: 1rem;
  /*text-align: right;*/
}
.tableTop {
  background-color: aliceblue;
  /*padding:0 !important;*/
  vertical-align: center;
  font-weight: bold;
  font-size: 1rem;
}
.dateInput #grossSelect {
  min-width: 150px;
  width: 9rem;
}
.centre {
  text-align: center;
}
.column-left {
  border-left: 1px #dee2e6 solid;
}
.column-right {
  border-right: 1px #dee2e6 solid;
}
.column-bottom {
  border-bottom: 1px #dee2e6 solid;
}
.column-bottom-hidden {
  border-bottom-style: hidden;
}
.medium.left {
  text-align: left;
}
.click-object {
  text-decoration: underline;
  color: blue;
  cursor: pointer;
}
.clicked-object {
  text-decoration: underline;
  color: purple;
  cursor: pointer;
}
.flex-details {
  display: flex;
}
.info-label {
  text-align: right;
  font-weight: bold;
}
