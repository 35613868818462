span.maxHeight {
  margin-right: 0.4rem;
}
span.tableSearch {
  margin-right: 0.4rem;
}

div.searchRange {
  display: inline-flex;
  align-items: center;
  width: 100%;
}

td.searchRange {
  background-color: #1f4984;
}

span.searchRange {
  line-height: 3rem;
  margin-right: 0.5rem;
}

button.searchRange {
  /* margin-top: 2px;
	border-radius: 0.25rem; */
  height: 2rem;
  padding: 0 1.3rem;
  border: none;
  border-radius: 1.3rem;
  background-color: #1f4984;
}

.searchRange {
  display: inline-flex;
  align-items: center;
  width: max-content;
}

.searchBy {
  margin-top: 0;
  padding-top: 0;
}

select.form-control.no-position,
textarea.form-control.no-position,
input.form-control.no-position .no-position {
  position: relative;
  top: 0;
}

@media (max-width: 600px) {
  div.searchRange {
    display: block;
    width: 100%;
  }

  .searchRange {
    width: 100%;
  }
}
