.arrow {
  width: 1rem;
}
.narrow {
  width: 8rem;
}
.medium {
  width: 12rem;
}
.wide {
  width: 30rem;
}
.justify-content-between {
  justify-content: space-between;
}

#inbox tbody tr.active td {
  overflow: visible;
  white-space: normal;
}
#inbox tbody td {
  max-width: 30rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
#inbox tbody tr {
  cursor: default;
}
div.DateInput__small {
  width: 40%;
  text-align: center;
}
input.DateInput_input__small {
  text-align: center;
}
.range {
  margin-bottom: 3rem;
  display: flex;
  align-items: center;
}
.dateInput {
  display: flex;
  flex-direction: row;
  min-width: 50%;
  margin-right: 1em;
  position: relative;
  top: 0;
}
.dateInput #fromDate,
.dateInput #toDate {
  min-width: 6rem;
}
#grossSelect {
  height: 2.65rem;
}

.full-width {
  width: 100%;
  flex: 0 0 100%;
}

@media (max-width: 600px) {
  .range {
    display: block;
  }

  .dateInput {
    display: block;
    margin-bottom: 1em;
    margin-right: 0;
  }

  .dateInput #grossSelect {
    min-width: 150px;
    display: block;
    position: relative;
    width: 100%;
  }

  button.maxHeight.btn.btn-primary {
    margin-top: 1em;
    margin-left: 0;
  }

  .maxHeight.form-control {
    width: 100%;
  }

  .exportLink {
    padding: 0 1.3rem;
    border-radius: 1.3rem;
    margin: auto 0.9rem 0;
  }
  .CalendarMonthGrid {
    max-width: 100%;
    z-index: 10;
  }
}
.topText {
  font-size: 0.7em;
}
.dateInput #grossSelect {
  min-width: 100px;
  width: 6.1rem;
}
.maxHeight {
  margin-top: auto;
  background-color: #1f4984;
}
.monthFilter {
  margin-top: auto;
}
.maxHeight.btn.btn-primary {
  height: 2rem;
  padding: 0 1.3rem;
  border: none;
  border-radius: 1.3rem;
  margin: auto 0.9rem 0;
}
.contact-importer.maxHeight.btn.btn-primary {
  margin-top: 1rem;
  margin-left: 0rem;
}
.calanderInput {
  margin-top: auto;
  max-width: 1rem;
}
.highlight {
  background-color: #f5faff;
}
.highlight td {
  border-bottom: 2px #77a6ff solid;
}
.table .show td {
  border-top: 0px transparent;
}
.hidden {
  display: none;
}
/*body .Home .lander{*/
/*padding:0.9rem 0;*/
/*}*/
.unread {
  font-weight: 650;
}
.inboxWrapper .range a.exportButton {
  text-decoration: underline;
  color: #0063ce;
  cursor: pointer;
}
.inboxWrapper .range a.exportButton:hover {
  color: #002975;
}
.floatRight {
  float: right;
}

.main {
  margin-top: 2rem;
}
.maxHeight.btn.btn-primary.floatRight {
  position: relative;
  top: -0.22rem; /*alligns inside text with other text*/
}
.recipientText {
  width: 80%;
  min-width: 22rem;
}
.backLink,
.exportButton {
  cursor: pointer;
}
td {
  max-width: 30%;
  /*width: 30%;*/
  /*border:1px solid black;*/
}
tr {
  cursor: default;
  /*border:1px solid black;*/
}
td.label {
  width: 15%;
  min-width: 15%;
}
td.button {
  width: 20%;
  min-width: 20%;
}
.form-control {
  height: 2.6rem;
  /*width:80%;*/
  /*min-width:22rem;*/
}
select.settings-form-control,
.settings-form-control {
  height: 2.6rem;
  width: 59%;
}
.settings-text-inline {
  padding-left: 50px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
input[type='text'].form-control:not(.is-invalid) {
  border: 1px solid #ced4da;
  border-radius: 5px;
  height: 2.6rem;
  margin-bottom: 1.5rem;
}

/* For second rule */
.auto input[type='text'].form-control:focus:not(.is-invalid) {
  border: 1px solid #ced4da;
  border-radius: 5px;
  height: 2.6rem;
}
input[type='number'].form-control {
  border: none;
  padding: 0;
  text-align: center;
  height: inherit;
}

.dataPicker {
  display: flex;
  align-items: baseline;
  width: 100%;
  gap: 127px;
}

.auto input[type='number']:focus.form-control {
  border-bottom: none;
  height: inherit;
}

textarea.form-control {
  margin-bottom: 1.5rem;
  resize: vertical;
  min-height: 70px;
}
.form-label {
  width: 100%;
}
.creditComponent {
  background-color: #f5faff;
  width: 100%;
  min-width: 22rem;
  display: -ms-inline-flexbox;
  display: flex;
  height: 6rem;
}
.CCouter {
  height: 90%;
  margin: auto auto auto 0;
  display: flex;
  flex-direction: column;
}
.CCouter:nth-child(2) {
  border-left: 2px #bbddff solid;
}
.CCinner {
  margin: auto auto auto 2rem;
}
.CCinner:nth-child(1) {
  text-align: left;
  font-size: 2rem;
}
.CCinner:nth-child(2) {
  text-align: left;
  font-size: 1rem;
}
.showHideLink {
  display: block;
  background-color: gainsboro;
  text-align: right;
}
.showHideLink i {
  position: relative;
  top: 5pt;
}
.showHideLink-left {
  display: flex;
  background-color: gainsboro;
  text-align: left;
  padding-left: 4px;
  padding-bottom: 5px;
  justify-content: space-between;
  align-items: flex-end;
  flex-direction: row;
}
.showHideLink-left i {
  position: relative;
  top: 4.5pt;
  cursor: pointer;
}

.showHideLink-left text {
  cursor: pointer;
}

.clear-filters {
  padding-right: 1rem;
  cursor: pointer;
  text-decoration: underline;
  color: #1f4984;
}

.advanced-filters {
  padding-right: 4px;
  cursor: pointer;
}

.filterContainer {
  display: block;
  border: 1px solid gainsboro;
  padding: 0;
}
.no-margins {
  padding: 2rem;
  border: 0;
}
.backLink > span {
  line-height: 1;
  position: relative;
  top: -0.4em;
}
.backLink {
  display: inline-block;
}
a {
  width: 100%;
}
a.maxHeight.btn.btn-primary {
  line-height: 1.9;
}
.detail-label {
  text-align: left;
  line-height: 2;
}
.contact-type {
  background-color: #1f4984;
}
.single-number {
  min-width: 4rem;
  max-width: 4rem;
  margin-right: 5px;
}
.multiple-number {
  min-width: 3.5rem;
  max-width: 3.5rem;
  margin-right: 5px;
}
.wembsms-list {
  list-style: none;
  padding-left: 0;
}
.wembsms-list li {
  padding: 0.8rem;
  padding-left: 0;
  /*border-bottom: 1px whitesmoke solid;*/
  cursor: pointer;
}

.sub-header {
  font-size: larger;
  width: auto;
  float: left;
}

.add-new-template {
  height: 2rem;
  padding: 0 1.3rem;

  margin-top: 0.4rem;
  margin-right: 0;
  margin-bottom: 0.2rem;

  border-radius: 1.3rem;
  background-color: #1f4984;
  border: none;
  color: #f5faff;
}
.add-new-template.border {
  height: 2rem;
  padding: 0 1.3rem;

  margin-top: 0.4rem;
  margin-right: 0;
  margin-bottom: 0.2rem;
  border-radius: 1.3rem;
  background-color: #1f4984;
  border: none;
  color: #f5faff;
  white-space: nowrap;
}
.tg {
  border-collapse: collapse;
  border-spacing: 0;
}
.tg td {
  font-family: Arial, sans-serif;
  font-size: 14px;
  padding: 10px 5px;
  border-style: solid;
  border-width: 1px;
  overflow: hidden;
  word-break: normal;
  border-color: black;
}
.tg th {
  font-family: Arial, sans-serif;
  font-size: 14px;
  font-weight: normal;
  padding: 10px 5px;
  border-style: solid;
  border-width: 1px;
  overflow: hidden;
  word-break: normal;
  border-color: black;
}
.tg .tg-0lax {
  text-align: left;
  vertical-align: top;
}
.tg .tg-baqh {
  text-align: center;
  vertical-align: top;
}
.delete-section {
  margin-top: 2rem;
}
.add-new-template:hover {
  color: #f5faff;
}

button.cancelUserType.btn.btn-danger {
  background-color: #d73226;
  height: 2rem;
  padding: 0 1.3rem;
  border-radius: 1.3rem;
  border: none;
  margin: auto 0.9rem 0;
}
.delete.btn-red.btn.btn-danger {
  float: right;
  background-color: #d73226;
  height: 2rem;
  padding: 0 1.3rem;
  border-radius: 1.3rem;
  margin: auto 0.9rem 0;
  margin-top: 0.3rem;
  margin-bottom: 0.5rem;
}
.delete.btn-red.btn.btn-danger:disabled {
  background-color: grey;
  border-color: grey;
}
.marginLeft {
  margin-left: 0.5rem;
}
.marginRight {
  margin-right: 0.5rem;
}
.pad-button.maxHeight.btn.btn-primary {
  margin-top: 0.6rem;
  margin-bottom: 0.6rem;
}

.exportLink {
  padding: 0 1.3rem;
  border-radius: 1.3rem;
}

.mobile-nav {
  background-color: #1e4984;
  height: 100vh;
  padding: 0;
}

.rw-widget > .rw-widget-container {
  width: 100%;
  max-width: 100%;
}

.rw-widget-input {
  text-align: left;
  letter-spacing: 0.6px;
}

.rw-datetime-picker {
  position: relative;
  margin-right: 2%;
}

.lander {
  width: 100%;
}

@media (max-width: 600px) {
  .quick-report {
    display: block;
  }
  .quick-report {
    display: none;
  }
  .rw-widget > .rw-widget-container {
    width: 100%;
    max-width: 100%;
  }
  .lander {
    width: 100%;
  }
}

.no-padding {
  padding: 0;
}

.table-check .form-check {
  margin-bottom: 0.5rem;
}

.WebSMS .form-group .form-control {
  margin-bottom: 0;
}

.remindText {
  font-size: 15px;
  line-height: 18px;
}

.indentSpaced {
  text-indent: 50px;
  padding: 10px;
  margin-bottom: 0;
}
