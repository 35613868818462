body {
  background: white;
}

iframe {
  border: none;
}

.material-icons.disabled {
  color: grey;
  cursor: default;
}

.help-icon {
  font-size: 0.9rem;
  border-bottom: 1px dotted;
  padding-left: 0px;
  cursor: help;
}

.btn-add-new {
  border-radius: 30px;
}

.btn:active {
  background-color: #023c75;
}

.btn:hover {
  background-color: #023c75;
}

.underline {
  text-decoration: underline;
}

.red {
  color: #d73226;
  cursor: pointer;
}

.blue {
  color: blue;
  cursor: pointer;
}

.btn-primary:disabled {
  margin-right: 0.5rem;
  background-color: grey;
  border: none;
}

.margin-right-small {
  margin-right: 0.5rem;
}

.btn-red {
  background-color: #d73226;
  color: white;
}

.btn-green {
  background-color: #28a745;
  border-color: #28a745;
}

.btn-red:hover {
  background-color: darkred;
  color: white;
}

.rounded-btn {
  border-radius: 0.25rem;
}

select.form-control,
textarea.form-control,
input.form-control {
  position: relative;
  margin-right: 0.5rem;
}

.visible-device {
  display: none;
}

.visible-desktop {
  max-width: 100vw;
  max-height: 100vh;
  display: contents;
}

.visible-desktop-login {
  max-width: 100vw;
  max-height: 100vh;
  display: block;
  margin-left: 0;
  margin-right: 0;
}

.visible-desktop-login-block {
  display: block;
  overflow: auto;
}

.visible-desktop-sideNav {
  display: block;
  overflow: auto;
}

@media (max-width: 600px) {
  .visible-device {
    display: contents;
    max-width: 100vw;
    max-height: 100vh;
  }

  .visible-desktop {
    display: none;
  }
}

.mobile-menu-icon {
  border: 0;
  background-color: transparent;
  color: white;
  float: right;
  padding: 0.2em 0.6em 0.3em;
}

.mobile-main-heading {
  background-color: #252629;
  max-width: 100vw;
  padding: 10px;
  padding-left: 30px;
}

.app .navbar-brand {
  font-weight: bold;
  margin: auto 3rem;
}

.Home .lander {
  text-align: left;
}

.app .navbar_local {
  height: 5.4rem;
  padding: 0 15px;
  background-image: linear-gradient(grey, #1b1919);
}

.app .navbar_development {
  height: 5.4rem;
  padding: 0 15px;
  background-image: linear-gradient(darkorange, #1b1919);
}

.app .navbar_testing {
  height: 5.4rem;
  padding: 0 15px;
  background-image: linear-gradient(purple, #1b1919);
}

.app .navbar_staging {
  height: 5.4rem;
  padding: 0 15px;
  background-image: linear-gradient(darkgreen, #1b1919);
}

.app .navbar_production {
  height: 5.4rem;
  padding: 0 15px;
  background-image: linear-gradient(#2e2e2e, #1b1919);
}

.row {
  height: calc(100vh - 5.4rem);
  /*width: calc(100vw - 3.5rem);*/
  width: calc(100vw);
  margin-right: 0;
  margin-left: 0;
}

.form-group.row {
  width: 100%;
}

.filter {
  margin: 3px;
  padding: 0;
  padding-top: 10px;
}

.filter-title {
  vertical-align: middle;
}

.filter-item {
  display: inline;
  vertical-align: middle;
  margin: 10px 2px 0 2px;
  padding: 0 15px 0 15px;
}

.filter-table {
  background-color: #ffffff;
}

.centre-screen {
  text-align: center;
  padding-top: 30px;
}

.container {
  padding-right: 0;
  padding-left: 0;
  margin-right: 0;
  margin-left: 0;
  width: 100%;
  max-width: 100%;
  min-width: 1280px;
  overflow: hidden;
}

.mobile-container {
  max-width: 86vw;
}

.inline {
  display: inline;
}

.inline-flex {
  display: inline-flex;
}

.btn-group-vertical {
  width: 100%;
}

.dropdown-item.active {
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
}

.btn-group-vertical .btn {
  padding: 1rem 0;
  padding-left: 10%;
  text-align: left;
}

.btn-light {
  width: 100%;
  background-color: transparent;
  border: none;
}

.btn-light:active {
  border-radius: 0;
}

.col-sm-2,
.col-sm-4 {
  padding: 0;
  max-width: 10%;
}
.col-sm-4-adjust {
  padding: 0;
  max-width: 175px;
}

.col-sm-8 p {
  margin-bottom: 0;
}

.btn.btn-light {
  color: white;
  /*color: black;*/
}

.navbar-expand .navbar-nav,
.dropdown {
  height: 100%;
  display: flex;
}

.navbar-expand .navbar-nav .nav-link {
  /*margin: auto;*/
  height: auto;
  padding: 1.8rem 1rem;
  color: white;
  border-bottom: solid 5px transparent;
  background-clip: padding-box;
}

.nav-admin-view {
  margin: auto;
  height: auto;
  padding: inherit;
  color: white;
  overflow: hidden;
}

.navbar-dark .navbar-nav .nav-link.active {
  /*background: #11115522;  */
  border-bottom: solid 5px #dc392bff;
  margin: auto;
}

.dropdown-item .none.nav-link {
  padding: 0;
  color: black;
}

.navbar-brand {
  padding-right: 3rem;
}

@media all and (max-width: 576px) {
  .row {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }

  .side-nav {
    padding: 0;
    background-color: #1f4984ff;
    width: 100%;
  }

  .app .navbar {
    align-content: center;
    max-width: 100vw;
    display: block;
    height: auto;
  }

  .app .navbar-brand {
    font-weight: bold;
    margin: 0;
    display: flow-root;
    width: 100%;
    min-width: 100% !important;
  }

  .navbar-brand {
    padding-right: 0;
  }

  .col-sm-9 {
    overflow: scroll;
    height: calc(100vh - 5.4rem);
    padding: 1rem;
  }

  .navbar-expand .navbar-nav .nav-link {
    padding: 0;
    display: block;
  }

  .navbar-expand .navbar-nav,
  .dropdown,
  .nav-item {
    flex-direction: row-reverse;
  }

  .btn-group-vertical .btn {
    padding-left: 15%;
  }
}

@media all and (min-width: 576px) {
  .side-nav {
    padding: 0;
    background-color: #1f4984ff;
    max-width: 20%;
  }

  .col-sm-9 {
    position: absolute;
    left: 20%;
    height: calc(100vh - 5.4rem);
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    overflow-y: scroll;
    width: calc(100vw - 20%);
    max-width: calc(100vw - 20%);
  }
}

textarea {
  resize: none;
}

.display-interactable {
  cursor: pointer;
}

.display-auto-cursor {
  cursor: auto;
}

.table-click {
  cursor: pointer;
}

.default-cursor {
  cursor: default;
}

.CalendarDay__selected_span {
  background: #1f4984ff;
  border: 1px double #1f4984ff;
}

.CalendarDay__selected_span:active,
.CalendarDay__selected_span:hover {
  background: #183968;
  border: 1px double #183968;
  color: #fff;
}

.DayPickerKeyboardShortcuts_show__bottomRight::before {
  border-right: 33px solid #1f4984ff;
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background: #183968;
  border: 1px double #183968;
}

.CalendarDay__hovered_span:active {
  background: #183968cc;
  border: 1px double #183968cc;
  color: #fff;
}

.CalendarDay__hovered_span,
.CalendarDay__hovered_span:hover {
  background: #18396880;
  border: 1px double #18396899;
  color: #fff;
}

.hide-error {
  opacity: 0;
  display: none;
}

.footer-sml {
  font-size: smaller;
  text-align: center;
  cursor: pointer;
}

.error-card {
  background-color: #dc392a;
}

/*.error-message {
  float:left;
  padding: .2em .6em .3em;
}*/

.info-card {
  width: 100%;
  background-color: #2adc42;
  position: absolute;
  z-index: 1000;
  /*transition: all 2s linear;*/
  bottom: 0;
}

.info-card p {
  width: 100%;
  padding: 0.2em 0.6em 0.3em;
}

/*.info-message {
  float:left;
  padding: .2em .6em .3em;
}*/

.close-button {
  width: auto;
  float: right;
  color: #757575;
  font-weight: 200;
  /*padding: .2em .6em .3em;*/
}

.ph-form-element-label {
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  color: #161616;
}

input[type='number'].form-control.num {
  border: 1px solid #ced4da;
  border-radius: 5px;
  height: 2.6rem;
  text-align: left;
}

.successMessage {
  background-color: #98fb98;
  border: 1px solid #4bb543;
  border-radius: 10px;
  padding: 0.5rem;
}

.dropdown-admin {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
}

.modal-body {
  white-space: pre-line;
  overflow-wrap: break-word;
}

.modal-xero {
  white-space: break-spaces;
}

span.header {
  font-weight: bold;
}

h3 {
  margin-top: 0.5rem;
}

.form-check {
  display: inline-block;
}

.svg-close-icon {
  width: 2em;
  height: 2em;
}

.svg-close-icon path,
.svg-close-icon polygon,
.svg-close-icon rect {
  fill: #000;
}

.svg-close-icon circle {
  stroke: #000;
  stroke-width: 1;
}

a {
  text-decoration: underline;
}

tr.collapsible-table td {
  padding: 0;
}

tr.collapsible-table div div {
  white-space: pre-wrap;
  padding: 12px;
}

.table-middle td {
  vertical-align: middle;
}

.table-middle tbody {
  cursor: pointer;
}

.icon-inline {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.align-item-centre {
  align-items: center;
}

.tooltip-standard-unicode > .tooltip-inner {
  max-width: 300px;
}

.tooltip-Record > .tooltip-inner {
  max-width: 300px;
}

.tooltip-credits-count > .tooltip-inner {
  max-width: 300px;
}

.tooltip-inner p {
  white-space: pre-line;
  margin-bottom: 4px;
}

.bold {
  font-weight: bold;
}

.y-scroll {
  overflow-y: scroll;
}

.no-scroll {
  overflow: hidden;
}

.full-width {
  width: 100%;
}

.full-width select {
  width: 100%;
}

.full-usage td,
.user-usage td,
.invoice-details td {
  border: 1px solid #dee2e6;
  text-align: center;
}

.big-text {
  font-size: 25px;
}

.campaign-text .list-group-item {
  padding: 0.2rem 0.5rem;
}

.campaign-text .info-label {
  padding-top: 1px;
}

.campaign-text-edit {
  display: flex;
  justify-content: space-between;
  padding: 0;
  border: 1px solid #ced4da;
}

.campaign-text-edit .padded {
  padding: 0.5rem 1.25rem;
}

.flex-div {
  display: flex;
}

.x-button:hover {
  fill: #dd4428;
  background-color: #fcbcac;
  cursor: pointer;
}

.add-button:hover {
  background-color: lightgrey;
  cursor: pointer;
}

.list-group-item .campaign-text-edit {
  border: 1px solid #ced4da;
}

.add-campaign {
  display: flex;
  justify-content: space-between;
  border: 1px solid #ced4da;
  border-radius: 5px;
  margin-bottom: 10px;
  padding-bottom: 0px;
}

.add-campaign input[type='text'].form-control {
  border: none;
  margin-bottom: 0px;
  margin-right: 0px;
}

.add-button {
  padding: 0.5rem 1.25rem;
}

.middle {
  vertical-align: middle;
}

.flex-between {
  display: flex;
  justify-content: space-between;
}

.flex-end {
  display: flex;
  align-items: flex-end;
}

.italic-grey {
  font-style: italic;
  color: darkgrey;
}

.campaign-type-select {
  min-width: 120px;
  background: #f2f2f2;
  border-bottom: none;
  border-top: none;
  border-left: none;
}

.campaign-list {
  flex: 1;
}

.campaign-list i {
  padding-left: 20px;
}

.table-new th {
  text-align: left;
  border-bottom: 1px #dee2e6 solid;
}

.table-new {
  border-bottom: 1px #dee2e6 solid;
  border-right: 1px #dee2e6 solid;
  border-left: 1px #dee2e6 solid;
}

.margin-auto {
  margin: auto;
}

.flexRight {
  margin: auto;
  margin-right: 0;
}

.btn-color {
  background-color: #1f4984;
  border: none;
}

.large-icon {
  font-size: 16px;
}

.searchRange.filter-label {
  justify-content: flex-end;
  padding-right: 10px;
  width: 30px;
  margin-left: 30px;
}

.searchRange.filtering-label {
  justify-content: flex-end;
  padding-right: 10px;
  width: 40mm;
  margin-left: 30px;
}

.searchRange.invoice-filter {
  justify-content: flex-end;
  padding-right: 10px;
  width: 90px;
}

.payment {
  margin: 5px 0px 5px 14px;
}

.no-margin {
  margin: 0;
}

input[type='text']::-webkit-input-placeholder {
  color: #b9b9b9;
}

.filter-control {
  width: 220px;
  margin-bottom: 0.8rem;
}

.input-margin-bottom {
  margin-bottom: 0.8rem;
}

.user-table tbody td {
  max-width: 30rem;
}

.customerRegister {
  padding: 0 0;
  width: 50rem;
  max-width: 50rem;
  margin: 0 auto;
  text-align: center;
  border: 1px solid lightgrey;
  border-radius: 2rem;
  margin-top: 2rem;
  margin-bottom: 3rem;
}

.RegisterPage {
  padding: 60px 0;
}

.wrap {
  white-space: normal;
}

.resendCode {
  color: blue;
  cursor: pointer;
}

.td-border td {
  border: 1px solid #dee2e6;
}
