@media all and (min-width: 480px) {
  .Login {
    padding: 4rem 0;
  }

  .Login form,
  .register {
    margin: 0 auto;
    max-width: 22rem;
  }
  .register {
    display: flex;
  }
  .register > span {
    margin: 0 auto;
  }
  .text-danger.error {
    text-align: left;
    padding-bottom: 0.8rem;
  }
  .register-button {
    text-align: center;
  }
}
