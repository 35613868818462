.provider.Home,
.customer.Home {
}

.provider.table,
.customer.table {
}

.provider.lander,
.customer.lander {
  margin-top: 0.6rem;
  margin-bottom: 3rem;
}

.flex {
  display: flex;
}

.flexAlignCenter {
  align-items: center;
}

.hintText {
  font-size: 0.8rem;
}

.createProviderButton {
  /* margin-top: 0.4rem; */
  background-color: #1f4984;
  height: 2rem;
  padding: 0 1.3rem;
  border-radius: 1.3rem;
  border: none;
  /* margin: auto 0.9rem 0; */
  margin-top: 0.3rem;
  margin-bottom: 0.5rem;
}

.maxHeight.btn.btn-primary {
  height: 2rem;
  padding: 0 1.3rem;
  border-radius: 1.3rem;
  /* margin: auto 0.9rem 0; */
  margin-top: 0.3rem;
  margin-bottom: 0.5rem;
}

.maxHeight.btn.btn-primary.flexRight {
  margin: auto;
  margin-right: 0;
}

.tableSearch.btn.btn-primary {
  height: 2rem;
  padding: 0 1.3rem;
  border-radius: 1.3rem;
  /* margin: auto 0.9rem 0; */
  margin-top: 0.3rem;
  margin-bottom: 0.5rem;
}

.delete.btn.btn-danger {
  background-color: #d73226;
  height: 2rem;
  padding: 0 1.3rem;
  border-radius: 1.3rem;
  margin: auto 0.9rem 0;
  margin-top: 0.3rem;
  margin-bottom: 0.5rem;
}

.btn-hint {
  height: 2rem;
  margin: 1.3rem 0;
  margin-top: 0.6rem;
  margin-bottom: 0.5rem;
}

input.configBuyInput {
  width: 50%;
}

input[type='radio'] {
  width: 1em;
  display: inline;
  top: 1rem;
}

.info-label-radio {
  text-align: left;
  font-size: 1em;
  vertical-align: text-bottom;
  margin-left: 1em;
  margin-bottom: 0.5em;
}

.medium {
  max-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.medium.left-wrap {
  white-space: normal;
  text-align: left;
}

.small {
  width: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.dontHide {
  overflow: visible;
}

.no-button.btn.btn-secondary {
  height: 2rem;
  padding: 0 1.3rem;
  border-radius: 1.3rem;
  margin-top: 0.3rem;
  margin-bottom: 0.5rem;
}

.json-view {
  white-space: pre-line;
  overflow-wrap: break-word;
  white-space: break-spaces;
}
.poolnumbers-table {
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 0%;
  text-align: left;
}
.checkbox-width {
  text-align: right;
  width: 0% !important;
  height: 0% !important;
}
.campaigns-dropdown-menu {
  padding-top: 0%;
  width: 75%;
  padding-bottom: 0%;
  padding-right: 0%;
  padding-left: 0%;
  height: 25%
}
.searchRange.device-filter {
  justify-content: flex-end;
  padding-right: 10px;
  width: 30mm;
}

.filters {
  padding-bottom: 1rem;
}

.count-success, .success {
  color: green;
}

.count-failed, .error {
  color: red;
}

.warning {
  color: orange;
}

.clickable {
  cursor: pointer;
}

.clickable:hover {
  background-color: #f0f0f0;
}

.loading-spinner {
  margin-right: 0.5rem;
  display: flex;
  align-items: center;
}

#batches-filters {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr auto;
  gap: 10px;
  margin-bottom: 1rem;
}

#batches-filters input[type="text"] {
  margin-bottom: 0;
}
