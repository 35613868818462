.provider.Home,
.customer.Home {
  /*width: 95%;*/
  /*margin: 0 auto;*/
}

.provider.table,
.customer.table {
  /*width: 95%;*/
  /*margin: 0 auto;*/
}

.provider.lander,
.customer.lander {
  margin-top: 0.6rem;
  margin-bottom: 3rem;
}

.flex {
  display: flex;
}

.hintText {
  font-size: 0.8rem;
}

.createProviderButton {
  margin-top: 0.4rem;
}

input.configBuyInput {
  width: 50%;
}
